<template>
  <div class="mainform barCodeManage">
    <div class="form">
      <el-form  class="content bascform disabled" :disabled="true" label-width="110px">
        <div class="col col4">
          <el-form-item label="采购单编号">
            <el-input v-model="formData.purchaseOrderNumber"></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="采购单行号">
            <el-input v-model="formData.lineNumber"></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="物料编号">
            <el-input v-model="formData.itemNumber"></el-input>
          </el-form-item>
        </div>
          <div class="col col4">
          <el-form-item label="订单数量">
            <el-input v-model="formData.barcodeQuantity"></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="物料描述">
            <el-input v-model="formData.itemMessage"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="warning">注：MAC和WWN额外申请数量请填写需要条码的物料数量*单位条码数量</div>
      <div class="tableList">
        <div class="table">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column  prop="type"  label="条码类型" ></el-table-column>
            <el-table-column  prop="total" label="单位数量/已下发总数量"></el-table-column>
            <el-table-column  prop="number" label="额外申请数量">
              <template #default="scope">
                <el-input v-model="scope.row.number" placeholder="请输入"></el-input>
              </template>
            </el-table-column>
            <el-table-column  prop="reason"  label="原因">
              <template #default="scope">
                <el-input v-model="scope.row.reason" placeholder="请输入"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupBarcodeDialogbody',
  props: ['formdata'],
  data () {
    return {
      formData: this.formdata,
      tableData: [
        {
          id: 'barCode',
          type: '条码',
          total: this.formdata.barcodeIssue,
          number: this.formdata.barcodeAdditional,
          reason: this.formdata.barcodeReason
        },
        {
          id: 'mac',
          type: 'MAC',
          total: this.formdata.macBase + '/' + this.formdata.macIssue,
          number: this.formdata.macAdditional,
          reason: this.formdata.macReason
        },
        {
          id: 'wwn',
          type: 'WWN',
          total: this.formdata.wwnBase + '/' + this.formdata.wwnIssue,
          number: this.formdata.wwnAdditional,
          reason: this.formdata.wwnReason
        }
      ]
    }
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
 @import '@/assets/css/elForm.scss';
 @import '@/assets/css/tableList.scss';
  .mainform{
    padding: 0;
    .form{
      padding: 0px;
    }
  }
.warning{
    padding-bottom: 10px;
    text-align: center;
    color: #ed5858;
  }
.barCodeManage .tableList{
  padding: 0;
  :deep(.table){
  .el-table td {
    padding: 4px 0;
    font-size: 14px;
    .el-input{
      line-height: 34px;
    }
    .el-input__inner {
      border: none;
      background: none;
      height: 29px;
      line-height: 29px
    }
    .el-button{
      padding: 3px 4px;
    }
  }
}
}
</style>
